<template>
  <!--
    The view for the CutConfig-component
  -->
  <Portlet
    :title="$t('cutConfig')"
    icon="cut"
  >
    <template slot="buttons">
      <button
        class="btn btn-sm btn-primary float-right mt-2 mb-2"
        @click="addCutConfig()"
      >
        <font-awesome-icon
          class="mr-1"
          icon="plus"
        />
        <span>
          {{ $t('add') }}
        </span>
      </button>
    </template>
    <CutConfigList
      ref="cutConfigList"
      @reloadAuditLogs="reloadAuditLogs"
    />
    <DatabaseAuditLogList
      ref="databaseAuditLogList"
      :is-cut-configs="true"
      @reload="reloadCutConfigList"
    /> 
  </Portlet>
</template>

<script>
export default {
  name: "CutConfig",
  components: {
    CutConfigList: () => import('@/components/VideoPostprocessing/CutConfig/CutConfigList.vue'),
    DatabaseAuditLogList: () => import('@/components/DatabaseAuditLogs/DatabaseAuditLogsList.vue')
  },
  metaInfo () {
    return {
      title: this.$t('videoPostProcessing.title')
    }
  },
  methods: {
    reloadAuditLogs () {
      this.$refs.databaseAuditLogList.reloadAuditLogs();
    },
    reloadCutConfigList () {
      this.$refs.cutConfigList.refreshAndClose();
    },
    addCutConfig () {
      this.$refs.cutConfigList.addCutConfig();
    }
  }
}
</script>
